<template>
  <div class="myAccount">
    <div class="account-upper">
      <div class="avatar-image">
        <img src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png" alt="User Avatar">
      </div>
      <div class="account-username">
        {{user.name}}
      </div>
      <div class="account-bar">
        <div class="bar-sector">
          <router-link to="/orders">
            <i class="fas fa-truck-loading"></i>
            Comenzi
          </router-link>
        </div>
        <div class="bar-sector">
          <router-link to="/history">
            <i class="fas fa-history"></i>
            {{ $t('txt.history') }}
          </router-link>
        </div>
        <div class="bar-sector">
          <a href="#" v-b-modal.modal="`modal-change-password`">
            <i class="fas fa-unlock-alt"></i>
            {{ $t('account.change_password') }}
          </a>
        </div>
        <!-- <div class="bar-sector">
          <a href="#">
            <i class="fas fa-bell"></i>
            Notificari
          </a>
        </div> -->
      </div>
    </div>
    <div class="account-information">
      <h1> Informatii personale </h1>
      <ul class="account-info-list">
          <li v-for="user_data in user_data_fields.data_column" :key="user_data.id">
              <p class="info-list-icon">
                  <i :class="user_data.dataIcon"></i>
              </p>
              <p> {{user_data.dataValue}} </p>
          </li>
        <!-- <li>
          <p class="info-list-icon"> <i class="fas fa-signature"></i> </p>
          <p> Luca Florin Adrian </p>
        </li>
        <li>
          <p class="info-list-icon"> <i class="fas fa-at"></i> </p>
          <p> florin.luca@webmagnat.ro </p>
        </li>
          <li>
          <p class="info-list-icon"> <i class="fas fa-mobile-alt"></i></p>
          <p> 0755 991 221 </p>
        </li> -->
      </ul>
    </div>
<!--    <button class="edit-button">-->
<!--      <i class="fas fa-plus"></i>-->
<!--    </button>-->
  <b-modal id="modal-change-password" class="item-modal change-modal" :title="$t('account.change_password')" hide-footer>
    <b-form @submit.prevent="changePassword" class="change-form">
      <b-form-group class="recover-form-group">
        <b-form-input
          id="change-p-old"
          v-model="oldPassword"
          type="password"
          required
          placeholder=""
          class="recover-form__input change-form__input"
        ></b-form-input>
        <label for="change-p-old"> {{ $t('recover_password.old_password') }} </label>
      </b-form-group>
      <b-form-group class="recover-form-group">
        <b-form-input
          id="change-p-first"
          v-model="newPassword"
          type="password"
          required
          placeholder=""
          class="recover-form__input change-form__input"
        ></b-form-input>
        <label for="change-p-old"> {{ $t('recover_password.new_password') }} </label>
      </b-form-group>
      <b-form-group class="recover-form-group">
        <b-form-input
          id="change-p-second"
          v-model="repeatPassword"
          type="password"
          required
          placeholder=""
          class="recover-form__input change-form__input"
        ></b-form-input>
        <label for="change-p-old"> {{ $t('recover_password.repeat_password') }} </label>
      </b-form-group>
      <div class="recover-form__button-container">
        <b-button class="app-button login-button recover-form__button change-form__button" type="submit"> {{ $t('recover_password.change_password') }} </b-button>
      </div>
      <p class="form-error" v-if="error"> {{ errorMessage }} </p>
      <p class="form-success" v-if="success"> {{ $t('recover_password.success') }} </p>
    </b-form>
  </b-modal>
  </div>
</template>

<script>
import ChangePassword from '@/api-services/change-password.service'
export default {
  name: 'Account',
  data () {
    return {
      user: this.$store.getters.user,
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
      error: false,
      success: false,
      errorMessage: ''
    }
  },
  computed: {
    user_data_fields () {
      return {
        data_column: [
          {
            dataValue: (this.user) ? this.user.name : null,
            dataIcon: 'fas fa-signature'
          },
          {
            dataValue: (this.user) ? this.user.email : null,
            dataIcon: 'fas fa-at'
          },
          {
            dataValue: (this.user) ? this.user.phone : null,
            dataIcon: 'fas fa-mobile-alt'
          }
        ]
      }
    }
  },
  methods: {
    changePassword () {
      if (this.oldPassword.length > 0 && this.newPassword === this.repeatPassword) {
        let data = {
          password: this.newPassword,
          password_confirmation: this.repeatPassword,
          old_password: this.oldPassword
        }
        console.log(data)
        this.success = false
        this.error = false
        ChangePassword.changePassword(data)
          .then((response) => {
            this.success = true
            this.resetForm()
            setTimeout(() => {
              this.success = false
              this.$bvModal.hide('modal-change-password')
            }, 3000)
          })
          .catch((error) => {
            if (error.response.status === 401 || error.response.status === 422) {
              console.log(error.response.data,'data')
              console.log(error.response.data.errors,'errors')
              if (typeof error.response.data.errors === 'object') {
                console.log('is')
                for (let [index, val] of Object.entries(error.response.data.errors)) {
                  if (Array.isArray(error.response.data.errors[index])) {
                    this.errorMessage = error.response.data.errors[index][0]
                  } else {
                    this.errorMessage = error.response.data.errors[index]
                  }
                  this.error = true
                  return false
                }
              } else {
                this.errorMessage = error.response.data.error
                this.error = true
                return false
              }
            }
          })
      } else {
        this.errorMessage = this.$t('recover_password.not_match')
        this.error = true
        this.resetForm()
      }
    },
    resetForm () {
      this.oldPassword = ''
      this.newPassword =  ''
      this.repeatPassword =  ''
    }
  },
  created () {
    this.$store.dispatch('hideLoading')
  }
}
</script>
